// --> React
import React from 'react';

// --> Packages
import { Container, Grid } from 'semantic-ui-react';

// --> Project Imports
import { ArticleCard, CurveGraphic } from 'components';
import { stockHome } from 'assets';
import { SERVICE } from 'routes';
import { fetchServicesSectionContent } from 'groq';
import { checkSeshStorageAddIfNeeded } from 'util';

// --> Component Imports
import Style from './servicesSection.module.scss';

export default function ServicesSection() {
	const [content, setContent] = React.useState(null);

	React.useEffect(() => {
		checkSeshStorageAddIfNeeded(`fmlc_servicessection__content`, setContent, fetchServicesSectionContent);
	}, []);

	return (
		content && (
			<section className={Style.SectionWrapper} style={{ backgroundImage: `url(${stockHome})` }}>
				<CurveGraphic pos='top' />
				<Container className={Style.SectionInner}>
					<Grid className={Style.SectionDetails}>
						<Grid.Row>
							<Grid.Column mobile={16} computer={9}>
								<div className={Style.Title}>
									<h1>{content.titleTop}</h1>
									<h1>{content.titleBottom}</h1>
								</div>
								<p className={Style.Description}>{content.subtitle}</p>
							</Grid.Column>
						</Grid.Row>
					</Grid>

					<ArticleCard.CardGroup>
						{Object.values(content.services).map(
							(service, i) =>
								i < 4 && (
									<ArticleCard
										to={`${SERVICE}/${service.slug.current}`}
										key={`${i}__${service.slug.current}`}
										title={service.title}
										summary={service.shortSummary}
										backgroundImage={service.mainImage?.asset?.url}
										linkText='Daha fazlası'
									/>
								)
						)}
					</ArticleCard.CardGroup>
				</Container>
			</section>
		)
	);
}
