// --> React
import React from 'react';

// --> Packages
import { Container } from 'semantic-ui-react';

// --> Project Imports
import { Loading, ArticleCard, BrowseFilters } from 'components';
import { PRODUCTS } from 'routes';
import { fetchProducts } from 'groq';
import { checkSeshStorageAddIfNeeded } from 'util';

// --> Component Imports
import Style from './products.module.scss';

export default function Products() {
	const [portfolioItems, setPortfolioItems] = React.useState(null);
	const [activeFilters, setActiveFilters] = React.useState([]);

	React.useEffect(() => {
		checkSeshStorageAddIfNeeded('fmlc__products', setPortfolioItems, fetchProducts);
	}, []);

	console.log(portfolioItems)
	return !portfolioItems ? (
		<Loading size='small' />
	) : (
		<Container as='section' className={Style.SectionWrapper}>
			<BrowseFilters items={portfolioItems} notTag='productType' setActiveCallback={setActiveFilters} />
			<ArticleCard.CardGroup>
				{portfolioItems.map((item, i) =>
					activeFilters.length > 0 && !BrowseFilters.itemHasActiveTag(activeFilters, item.productType) ? null : (
						<ArticleCard
							key={`${item?.slug?.current}_${i}`}
							to={`${PRODUCTS}/${item?.slug?.current}`}
							title={item?.title}
							backgroundImage={item?.photos[0].asset.url}
							summary={item?.shortSummary}
							linkText='Detaylı İncele'
						/>
					)
				)}
			</ArticleCard.CardGroup>
		</Container>
	);
}
