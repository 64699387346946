// --> React
import React from 'react';
import { Link } from 'react-router-dom';

// --> Packages
import { Container, Grid } from 'semantic-ui-react';
import SanityBlockContent from '@sanity/block-content-to-react';

// --> Project Imports
import { Button } from '../../../components';
import { PRODUCTS } from '../../../routes';

// --> Component Imports
import style from './profLawnCare.module.scss';
import { fetchProfLawncareContent } from 'groq';
import { checkSeshStorageAddIfNeeded } from 'util';

export default function ProfLawnCareSection() {
	const [content, setContent] = React.useState(null);
	const circleRef = React.useRef(null);
	const [isMobile, setIsMobile] = React.useState(false);

	React.useEffect(() => {
		const handleResize = () => {
			// Ekran genişliği 600'den büyükse isMobile'ı false yap
			setIsMobile(window.innerWidth < 600);
		};

		window.addEventListener('resize', handleResize);

		// İlk boyut kontrolü
		handleResize();

		// Temizlik işlemi
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);


	React.useEffect(() => {
		if (circleRef.current && content) {
			let circle = document.getElementById('circleImg');
			const vidId = content.link ? content.link.split('watch?v=')[1].split('&')[0] : '';


			// Create the iframe element
			let iframe = document.createElement('iframe');
			iframe.width = circleRef.current.clientWidth;  // Set the width based on the container
			iframe.height = circleRef.current.clientHeight;  // Set the height based on the container
			iframe.src = `https://www.youtube.com/embed/${vidId}`;
			iframe.title = 'YouTube video player';
			iframe.frameBorder = '0';
			iframe.allow = 'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture';
			iframe.allowFullscreen = true;

			// Remove circular styling and apply rectangle settings
			iframe.style.borderRadius = '0';  // No border-radius for a rectangle
			iframe.style.objectFit = 'cover';  // Cover ensures the video fits the container
			iframe.style.pointerEvents = 'auto';  // Allow interaction with the video

			// Append the iframe to the container
			circle.appendChild(iframe);

		}
	}, [content]);



	React.useEffect(() => {
		checkSeshStorageAddIfNeeded(`fmlc_proflawncare__content`, setContent, fetchProfLawncareContent);
	}, []);

	return React.useMemo(
		() =>
			content && (
				<Container fluid className={style.Outer} as={'section'}>
					<Container className={style.Inner}>
						<div className={`${style.CircleOne} ${style.Circle}`} />
						<div className={`${style.CircleTwo} ${style.Circle}`} />

						<Grid>
							<Grid.Row >
								<Grid.Column stretched mobile={16} computer={8} className={style.Column}>
									<div className={style.Content}>
										<h1>{content.title}</h1>
										<h3>{content.subtitle}</h3>
										<SanityBlockContent blocks={content.body} />
										<Link to={PRODUCTS}>
											<Button>Detaylı İncele</Button>
										</Link>
									</div>

								</Grid.Column>
								{isMobile ?

									<Grid.Column
										style={{ marginBottom: 50, display: "flex", justifyContent: "center", width: 600 }}
									>
										<iframe
											width={"100%"}
											height={350 / 1.7777}
											src={`https://www.youtube.com/embed/${content.link ? content.link.split('watch?v=')[1].split('&')[0] : ''}`}
											title='YouTube video player'
											frameBorder='0'
											allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
											allowFullScreen></iframe>

									</Grid.Column> :
									<Grid.Column computer={8} className={style.Column}>
										<div ref={circleRef} className={style.CircleImage} id='circleImg'></div>
									</Grid.Column>
								}

							</Grid.Row>
						</Grid>
					</Container>
				</Container>
			),
		[content, isMobile]
	);
}
