// --> React
import React from 'react';

// --> Project Imports
import { PageHeader, ProductsSection } from 'components';
import { background1 } from 'assets';

// Component Import
import ViewWrapper from './ViewWrapper';

export default function Products() {
	return (
		<ViewWrapper>
			<PageHeader title="Ürünlerimize Göz Atın" image={background1} imgPos='top' />
			<ProductsSection />
		</ViewWrapper>
	);
}
