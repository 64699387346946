// --> React
import React, { useState } from 'react';
// import { Link } from 'react-router-dom';

// --> Project Imports
import { SERVICE, ABOUT_US, VIDEOS, OUR_WORK, HOME, ESTIMATE, PRODUCTS } from 'routes';
import { Button, Modal } from 'components';
import { PHONE, LEAF } from 'icons';

// --> Components Imports
import Drawer from './drawer/Drawer';
import Link from './link/Link.jsx';
import Style from './mobileNav.module.scss';
import Toggle from './toggle/Toggle.jsx';

import { fetchGeneralInfo } from 'groq';
import { checkSeshStorageAddIfNeeded } from 'util';

export default function MobileNav({ services = [] }) {
	const [open, toggleOpen] = React.useState(false);
	const [contactOpen, toggleContactOpen] = React.useState(false);
	const [number, setNumber] = useState("")

	React.useEffect(() => {
		checkSeshStorageAddIfNeeded(
			`fmlc_generalInfo`,
			(generalInfo) => {
				setNumber(generalInfo.whatsapp)
			},
			fetchGeneralInfo
		);
	}, []);

	return (
		<>
			<div
				className={Style.ContactWrapper}
				data-contact-open={contactOpen ? 1 : 0}
				data-toggle-open={open ? 1 : 0}>
				<Button space='10x' icon={LEAF} onClick={() => toggleContactOpen(true)} color='secondary'>
					Bize ulaşın
				</Button>
				<Modal isOpen={contactOpen} title='Bize Ulaşın' callback={toggleContactOpen}>
					<Button.FluidWrapper>
						<Button
							as={Link}
							to={ESTIMATE}
							icon={LEAF}
							onClick={() => toggleContactOpen(false)}
							fluid
							space='10y'>
							Fiyat alın
						</Button>
						<Button
							as={'a'}
							href={`tel:${number}`}
							onClick={() => toggleContactOpen(false)}
							space='10y'
							fluid
							icon={PHONE}>
							Şimdi arayın
						</Button>
					</Button.FluidWrapper>
				</Modal>
			</div>
			<div className={Style.Container}>
				<Toggle toggle={() => toggleOpen(!open)} open={open} />
				<Drawer open={open} toggle={toggleOpen}>
					<Link.Container>
						<Link toggle={toggleOpen} to={HOME}>
							Ana Sayfa
						</Link>
						<Link toggle={toggleOpen} to={ABOUT_US}>
							Hakkımızda
						</Link>
						<Link toggle={toggleOpen} to={PRODUCTS}>
							Ürünlerimiz
						</Link>
						<Link.SectionLabel>Galeri</Link.SectionLabel>
						<Link.SubLinkWrapper>
							<Link toggle={toggleOpen} to={OUR_WORK}>
								Çalışmalarımız
							</Link>
							<Link toggle={toggleOpen} to={VIDEOS}>
								Videolar
							</Link>
						</Link.SubLinkWrapper>

						<Link.SectionLabel>Hizmetler</Link.SectionLabel>
						<Link.SubLinkWrapper>
							{services.map((s, i) =>
								!s.slug || !s.slug.current ? null : (
									<Link
										key={`${s.slug.current}_${i}`}
										toggle={toggleOpen}
										to={`${SERVICE}/${s.slug.current}`}>
										{s.title}
									</Link>
								)
							)}
						</Link.SubLinkWrapper>

						<Button
							as={'a'}
							href={`tel:${number}`}
							onClick={() => toggleOpen(false)}
							space='10y'
							fluid
							icon={PHONE}
							color='secondary'>
							Şimdi Arayın
						</Button>
					</Link.Container>
				</Drawer>
			</div>
		</>
	);
}
