// --> React
import React from 'react';
import { Link } from 'react-router-dom';

// --> Packages
import { Container, Grid } from 'semantic-ui-react';

// --> Project Imports
import { backgroundMowing } from 'assets';
import BannerLogo from "assets/Logo.png"
import { Button } from 'components';
import { YOUTUBE } from 'icons';
import { VIDEOS } from 'routes';

// --> Component Imports
import style from './aboutHeader.module.scss';

export default function AboutHeader({ content }) {
	return (
		content && (
			<header className={style.HeaderWrapper} style={{ backgroundImage: `url(${backgroundMowing})` }}>
				<div className={style.Overlay} />
				<Container className={style.Inner}>
					<img style={{ marginBottom: 30, marginTop: 50 }} src={BannerLogo} alt='Full Moon Logo Banner' />

					<Grid className={style.ButtonWrapper}>
						<Grid.Row only='tablet computer'>
							<Button as={Link} to={VIDEOS} space={'10xy'} color='secondaryTransp' icon={YOUTUBE}>
								Videolar
							</Button>
						</Grid.Row>
						<Grid.Row only='mobile'>
							<Button.FluidWrapper>
								<Button
									as={Link}
									to={VIDEOS}
									space={'10y'}
									fluid
									color='secondaryTransp'
									icon={YOUTUBE}>
									Videolar
								</Button>
							</Button.FluidWrapper>
						</Grid.Row>
					</Grid>
				</Container>
			</header>
		)
	);
}
