// --> React
import React, { useState } from 'react';

// --> Packages
import { motion } from 'framer-motion';

// --> Project Imports
import { motion_variants_nav } from 'util';
import { Button } from 'components';
import { VIDEOS, OUR_WORK, SERVICE, ABOUT_US, ESTIMATE, HOME, PRODUCTS } from 'routes';
import { LEAF, PHONE } from 'icons';

// --> Component Imports
import NavBranding from './branding/Branding';
import DropMenu from './links/dropMenu/DropMenu';
import RootLink from './links/rootLink/RootLink';
import Style from './descktopNav.module.scss';

import { fetchGeneralInfo } from 'groq';
import { checkSeshStorageAddIfNeeded } from 'util';

export default function DesktopNav({ services }) {
	const [scrolled, setScrolled] = React.useState(false);
	const [number, setNumber] = useState("")

	React.useEffect(() => {
		checkSeshStorageAddIfNeeded(
			`fmlc_generalInfo`,
			(generalInfo) => {
				setNumber(generalInfo.whatsapp)
			},
			fetchGeneralInfo
		);
	}, []);

	React.useEffect(() => {
		const appWrapper = document.getElementById('app');
		appWrapper.addEventListener('scroll', (e) => {
			if (appWrapper.scrollTop > 150 && scrolled === false) {
				setScrolled(true);
			} else if (appWrapper.scrollTop <= 150 && scrolled === true) {
				setScrolled(false);
			}
		});
	}, [scrolled]);

	return (
		<div className={Style.Container}>
			<motion.nav
				className={Style.ContainerInner}
				animate={scrolled === true ? 'shortNav' : 'tallNav'}
				variants={motion_variants_nav.desktop}>
				<NavBranding scrolled={scrolled} />
				<div className={Style.CenterLinks}>
					<RootLink to={HOME}>Ana Sayfa</RootLink>
					<RootLink to={ABOUT_US}>Hakkımızda</RootLink>
					<RootLink to={PRODUCTS}>Ürünlerimiz</RootLink>
					<DropMenu
						links={services
							.map((s) =>
								!s.slug || !s.slug.current
									? null
									: {
										label: s.title,
										to: `${SERVICE}/${s.slug.current}`,
									}
							)
							.filter((l) => l !== null)}>
						Hizmetler
					</DropMenu>
					<DropMenu
						links={[
							{ label: 'Videolar', to: VIDEOS },
							{ label: 'Çalışmalarımız', to: OUR_WORK },
						]}>
						Galeri
					</DropMenu>

				</div>
				<div className={Style.RightLinks}>
					<RootLink to={ESTIMATE} hover={false} padding={false}>
						<Button thin icon={LEAF} color='secondary'>
							Fiyat alın
						</Button>
					</RootLink>
					<RootLink href={`tel:${number}`} hover={false}>
						<Button icon={PHONE} thin color='none'>
							Şimdi arayın
						</Button>
					</RootLink>
				</div>
			</motion.nav>
		</div>
	);
}
