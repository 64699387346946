// --> React
import React, { useState } from "react";
import { Link } from 'react-router-dom';
// --> Packages
import { Container, Grid } from 'semantic-ui-react';

// --> Project Imports
import { banner500 } from 'assets';
import { Button, SocialIcon } from 'components';
import { LEAF } from 'icons';
import { HOME, ESTIMATE } from 'routes';
// --> Component Imports
import style from './footer.module.scss';

import { fetchGeneralInfo } from 'groq';
import { checkSeshStorageAddIfNeeded } from 'util';
import { Map, Marker, APIProvider } from "@vis.gl/react-google-maps";

export default function Footer() {
	const [isMobile, setIsMobile] = React.useState(false);
	const [number, setNumber] = useState("")
	const [facebook, setFacebook] = useState("")
	const [yandex, setYandex] = useState("")
	const [instagram, setInstagram] = useState("")
	const [youtube, setYoutube] = useState("")

	React.useEffect(() => {
		const handleResize = () => {
			// Ekran genişliği 600'den büyükse isMobile'ı false yap
			setIsMobile(window.innerWidth < 600);
		};

		window.addEventListener('resize', handleResize);

		// İlk boyut kontrolü
		handleResize();

		// Temizlik işlemi
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	React.useEffect(() => {
		checkSeshStorageAddIfNeeded(
			`fmlc_generalInfo`,
			(generalInfo) => {
				setNumber(generalInfo.whatsapp)
				setFacebook(generalInfo.facebook)
				setYandex(generalInfo.yandex)
				setInstagram(generalInfo.instagram)
				setYoutube(generalInfo.youtube)
			},
			fetchGeneralInfo
		);
	}, []);

	return (
		<footer className={style.FooterWrapper}>
			<div className={style.Top}>
				<Container>
					<Grid className={style.Grid}>
						<Grid.Row centered>
							<Grid.Column mobile={16} tablet={10} computer={10}>
								<h3>Sizin için çalışıyoruz</h3>
								<p>
									Fiyat almak için hemen formumuzu doldurun veya bizimle şimdi iletişime geçin!
								</p>
							</Grid.Column>
							<Grid.Column only='tablet computer' tablet={6} computer={6}>
								<Link to={ESTIMATE}>
									<Button floated='right' color='secondary' icon={LEAF}>
										Fiyat alın
									</Button>
								</Link>
							</Grid.Column>

							<Grid.Column only='mobile' mobile={16}>
								<Link to={ESTIMATE}>
									<Button selfCenter space={'10xy'} color='secondary' thin icon={LEAF}>
										Fiyat alın
									</Button>
								</Link>
							</Grid.Column>
						</Grid.Row>
					</Grid>
				</Container>
			</div>
			<div className={style.Bottom}>
				<Container>
					<Grid className={style.Grid}>
						<Grid.Row centered stretched>
							<Grid.Column style={{ marginBottom: 50 }} tablet={10} className={style.FooterBrand}>
								<div style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }} to={HOME}>
									<img style={{ marginBottom: 20, backgroundColor: "white", padding: 20, borderRadius: 20 }} className={style.Logo} src={banner500} alt='Footer Branding Banner' />
									<div className={style.SocialIconWrapper}>
										{facebook && <SocialIcon link={facebook} margin='0 4px' type={'fb'} />}
										{yandex && <SocialIcon link={yandex} margin='0 4px' type={'yandex'} />}
										{instagram && <SocialIcon link={instagram} margin='0 4px' type={'instagram'} />}
										{youtube && <SocialIcon link={youtube} margin='0 4px' type={'youtube'} />}
										{number && <SocialIcon link={`https://wa.me/${number}`} margin='0 4px' type={'wp'} />}
									</div>
								</div>
							</Grid.Column>
							<Grid.Column mobile={16} tablet={6} computer={6} className={style.InfoWrapper}>


								<APIProvider apiKey={"AIzaSyD71dSLDQPkUA16-w4wlYrQU2nADlokJLE"}>
									<Map
										style={{
											width: isMobile ? 350 : 500,
											height: isMobile ? 400 : 400
										}}
										defaultZoom={14}
										defaultCenter={{
											lat: 38.209423,
											lng: 27.638368,
										}}
										gestureHandling={"greedy"}
									>
										<Marker position={{
											lat: 38.209423,
											lng: 27.638368,
										}} />
									</Map>
								</APIProvider>
								{/* <iframe title="map" src="https://yandex.com.tr/map-widget/v1/org/gurkan_botanik/106574189522/?ll=27.637204%2C38.192187&z=13" width={isMobile ? "350" : "500"} height={isMobile ? "500" : "400"} style={{ position: "relative" }}></iframe> */}
							</Grid.Column>
						</Grid.Row>
					</Grid>
				</Container>
				<div style={{ display: "flex", justifyContent: "center", cursor: "pointer", marginLeft: 50, marginRight: 50 }} onClick={() => window.open("https://wa.me/905417793098", "_blank")}>Bu site Ege Çetiner tarafından yapılmıştır. Yazılım çözümleri için tıklayınız.</div>
			</div>
		</footer>

	);
}
