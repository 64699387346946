// React
import React from 'react';

// Packages
import { Container, Grid } from 'semantic-ui-react';

// Project Imports
import { backgroundFamily } from 'assets';
import { CustomerReview } from 'components';
import { fetchComments } from 'groq';
import { checkSeshStorageAddIfNeeded } from 'util';

// Local Styling
import style from './happyClients.module.scss';

export default function HappyClients() {
	const [content, setContent] = React.useState(null);

	React.useEffect(() => {
		checkSeshStorageAddIfNeeded(`fmlc_comments`, setContent, fetchComments);
	}, []);

	console.log(content)

	return (
		<section className={style.SectionWrapper} style={{ backgroundImage: `url(${backgroundFamily})` }}>
			<div className={style.Overlay} />
			<Container className={style.Inner}>
				<Grid stretched className={style.Grid}>
					<Grid.Row stretched>
						<Grid.Column mobile={13} tablet={11} computer={10}>
							<div className={style.Title}>
								<h1>{content?.title}</h1>
								<h1>{content?.subtitle}</h1>
							</div>
							<div className={style.ReviewsWrapper}>
								<CustomerReview imgLink={content?.mainImage.asset.url} name={content?.name} comment={content?.comment} />
							</div>
						</Grid.Column>
					</Grid.Row>
				</Grid>
			</Container>
		</section>
	);
}
