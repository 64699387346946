// --> React
import React from 'react';
import { useParams } from 'react-router-dom';

// --> Project Imports
import { Loading, Slider, ProductSection } from 'components';
import { checkSeshStorageAddIfNeeded } from 'util';
import { fetchProduct } from 'groq.js';
import ViewWrapper from './ViewWrapper.jsx';

export default function Product() {
	const [portfolioItem, setPortfolioItem] = React.useState(null);
	const { slug } = useParams();

	React.useEffect(() => {
		checkSeshStorageAddIfNeeded(`fmlc_${slug}__product`, setPortfolioItem, () => fetchProduct(slug));
	}, [slug]);
	return !portfolioItem ? (
		<Loading size='screen' />
	) : (
		<ViewWrapper>
			<Slider srcUrls={portfolioItem?.photos.map((photo) => photo?.asset.url)} />
			<ProductSection portfolioItem={portfolioItem} />
		</ViewWrapper>
	);
}
