// --> React
import React from 'react';

// --> Project Imports
import { PageHeader, BrowseVideos } from 'components';
import { backgroundHouse } from 'assets';
import ViewWrapper from './ViewWrapper';

export default function VideosPage() {
	return (
		<ViewWrapper>
			<PageHeader title='Videolar' sub='Bizi izleyin ve ilham alın!' image={backgroundHouse} imgPos='bottom' />
			<BrowseVideos />
		</ViewWrapper>
	);
}
