// --> React
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

// --> Packages
import { Container, Grid } from 'semantic-ui-react';

// --> Project Imports
import { Button } from 'components';
import { placeholderLawn, banner1000, bannerVert_700 } from 'assets';
import { OUR_WORK, ESTIMATE } from 'routes';
import { MOWER, LEAF } from 'icons';
// --> Component Imports

import { fetchGeneralInfo } from 'groq';
import { checkSeshStorageAddIfNeeded } from 'util';

import style from './homeLanding.module.scss';

export default function HomeLandingSection() {
	const [slogan, setSlogan] = useState("")

	React.useEffect(() => {
		checkSeshStorageAddIfNeeded(
			`fmlc_generalInfo`,
			(slogan) => { setSlogan(slogan.landingSlogan) },
			fetchGeneralInfo
		);
	}, []);

	const Header = ({ vid = null }) => {
		if (slogan) {
			return (
				<header
					className={style.SectionWrapper}
					style={{ backgroundImage: vid ? '' : `url(${placeholderLawn})` }}>
					<Container>
						<Grid className={style.Wrapper}>
							<Grid.Row>
								<Grid.Column className={style.LogoDesktop} tablet={16} computer={16} only='tablet computer'>
									<img src={banner1000} alt='Full Moon Banner' />
								</Grid.Column>
								<Grid.Column className={style.LogoMobile} mobile={16} only='mobile'>
									<img src={bannerVert_700} alt='Full Moon Banner' />
								</Grid.Column>

								<Grid.Column textAlign='center' mobile={16}>
									<h3 style={{ marginTop: 30 }}>{slogan}</h3>
								</Grid.Column>

								<Grid.Column textAlign='center' tablet={16} computer={16} only='tablet computer'>
									<div className={style.ButtonWrapper}>
										<Button as={Link} to={ESTIMATE} space='10x' color='secondaryTransp' icon={LEAF}>
											Fiyat alın
										</Button>
										<Button space='10x' as={Link} to={OUR_WORK} color='secondaryTransp' icon={MOWER}>
											Çalışmalarımız
										</Button>
									</div>
								</Grid.Column>

								<Grid.Column textAlign='center' mobile={16} only='mobile'>
									<Button.FluidWrapper>
										<Button
											as={Link}
											to={ESTIMATE}
											fluid
											space='10y'
											color='secondaryTransp'
											icon={LEAF}>
											Fiyat alın
										</Button>
										<Button fluid color='secondaryTransp' as={Link} to={OUR_WORK} icon={MOWER}>
											Çalışmalarımız
										</Button>
									</Button.FluidWrapper>
								</Grid.Column>
							</Grid.Row>
						</Grid>
					</Container>
					<div className={style.Overlay} />
				</header>
			);
		}

	};

	return (
		<Header />
	);
}
